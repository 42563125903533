<template>
  <div v-if="showBanner" class="w-full" :class="`bg-brand-${alertColour}-200`">
    <div class="container">
      <div class="text-brand-grey-700 font-bold py-3 flex justify-between">
        <div v-html="alertTitle"></div>
        <button class="" @click="onClose">
          <Icon
            name="IconClose"
            class="mr-2 text-xl font-bold"
            :title="`close notification message`"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useHomepageStore } from '~~/stores/homepage'
import { stringEmpty } from '~/utils/helpers'

const homepageStore = useHomepageStore()
await useLazyAsyncData(() => homepageStore.fetch().then(() => true))
const { data: homepageData } = storeToRefs(homepageStore)

const alertBannerCookie = ref(
  useCookie('alertBanner', {
    maxAge: 10800, // ~3 hours
    default: () => 0
  })
)

// Computed
const alertTitle = computed(() => {
  return homepageData.value?.alert?.title?.value
})
const alertColour = computed(() => {
  return homepageData.value?.alert?.colour?.value
})
const showBanner = computed(() => {
  return (
    !stringEmpty(alertTitle.value) &&
    alertBannerCookie.value !== calcHash(alertTitle.value)
  )
})

// Methods
function onClose() {
  alertBannerCookie.value = calcHash(alertTitle.value)
}

function calcHash(val?: string) {
  if (!val) return 0
  let hash = 0
  for (let i = 0; i < val.length; ++i) {
    const c = val.charCodeAt(i)
    hash = (hash << 5) - hash + c
  }
  return hash
}
</script>
